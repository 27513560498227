import React from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import {
  HIDE_SIGN_IN_POPUP_MODAL,
  DISPLAY_CUSTOMER_ALREADY_EXISTS
} from "@constants/accountTypes";
import { defaultAction } from "@actions/defaultAction";
import { useDispatch, useSelector } from "react-redux";

const CustomerExists = () => {
  const dispatch = useDispatch();
  const customerExists = useSelector(
    state => state.popupsActions.customerExists
  );
  const showSignInPopUpModal = useSelector(
    state => state.userActions.showSignInPopUpModal
  );

  const closeCustomerExists = () =>
    dispatch(defaultAction(DISPLAY_CUSTOMER_ALREADY_EXISTS, false));

  const handleClick = () => {
    if (showSignInPopUpModal) {
      dispatch(defaultAction(HIDE_SIGN_IN_POPUP_MODAL));
      closeCustomerExists();
    }
  };

  return (
    <Modal
      className="Modal__Bootstrap modal-dialog"
      isOpen={customerExists}
      onRequestClose={closeCustomerExists}
      contentLabel="Error"
    >
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" onClick={closeCustomerExists}>
            <span aria-hidden="true">&times;</span>
            <span className="sr-only">Close</span>
          </button>
          <h4 className="modal-title partner-specific-color">
            Customer Already Exists
          </h4>
        </div>
        <div className="modal-body">
          The customer you are signing up for already exists. Please{" "}
          <Link onClick={handleClick} to="/login/" style={{ color: "#0275d8" }}>
            {" "}
            login{" "}
          </Link>{" "}
          to your account. If you do not remember your password, you can reset
          it{" "}
          <Link
            onClick={handleClick}
            to="/pass-assist/"
            style={{ color: "#0275d8" }}
          >
            here.
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default CustomerExists;
