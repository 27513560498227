import { getCartsSignIn } from "./getCartsSignIn";
import { getPaymentMethods } from "./getPaymentMethods";
import { getCustomerProfile } from "./getCustomerProfile";
import { handleRedirectOnLogin } from "./accountActions";
import { sendBugsnagError } from "@utils/Bugsnag";

export const verifyDevice = (code, ctx) => async (dispatch, getState, api) => {
  const { userInfo } = getState();
  try {
    const deviceResults = await api.put("v3.1/devices/verify", {
      verification_code: +code.trim(),
      customerId: userInfo.customerVerificationId
    });
    if (deviceResults.data && deviceResults.data.entryAttempts > 4) {
      throw new Error("Number of verification attempts exceeded.");
    }
    if (
      deviceResults.data.hasOwnProperty("deviceVerified") &&
      !deviceResults.data.deviceVerified
    ) {
      throw new Error("Invalid code provided.");
    }

    const actions = [dispatch(getCartsSignIn()), dispatch(getPaymentMethods())];

    await dispatch(getCustomerProfile());
    await Promise.all(actions);
    handleRedirectOnLogin({
      dispatch,
      ctx
    });
  } catch (error) {
    sendBugsnagError(error, "actions/verifyDevice");
    let msg;
    if (error.message === "Invalid code provided.") {
      msg =
        "Please check your email and make sure you entered the correct verification code.";
    } else if ("Device Not Found" === error.message) {
      msg = "Sorry, we could not find this device on our systems.";
    } else if ("Device is verified" === error.message) {
      ctx.props.history.push("/");
      return err;
    } else if (
      [
        "Number of verification attempts exceeded.",
        "Please contact customer service"
      ].includes(error.message)
    ) {
      msg =
        "Sorry, you have tried to verify this device too many times. Please contact support@cardcash.com for assistance.";
    } else {
      msg =
        "We are having a problem verifying your device. If this issue persists, please contact support@cardcash.com for assistance.";
    }
    return msg;
  }
};

export const resendVerifyDeviceCode = () => async (dispatch, getState, api) => {
  const { userInfo } = getState();
  try {
    await api.put("v3.1/devices/resend-code", {
      customerId: userInfo.customerVerificationId
    });
  } catch (error) {
    sendBugsnagError(error, "actions/resendVerifyDeviceCode");
    return (
      error?.response?.data?.message[0] ||
      "Please try again or contact customer service"
    );
  }
};
