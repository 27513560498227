import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import classNames from "classnames";
import { verifyDevice } from "@actions/verify";
import { defaultAction } from "@actions/defaultAction";
import { resendVerifyDeviceCode } from "@actions/verify";
import { SHOW_DEVICE_VERIFY_POPUP_MODAL } from "@constants/actionTypes";
import { HIDE_SIGN_IN_POPUP_MODAL } from "@constants/accountTypes";
import DefaultCustomButton from "@modules/Components/DefaultCustomButton";
import SmallButtonSpinner from "@components/SmallButtonSpinner";
import StepperInputs from "@components/StepperInputs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

const Container = styled.div`
  width: fit-content;
  margin: 0 auto;
  width: 579px;
  height: 595px;
  background-color: #f8f7f8;
  padding-top: ${props => (props.popup ? "0px" : "60px")};
  border-radius: ${props => (props.popup ? "30px" : "0px")};

  @media (max-width: 580px) {
    width: 100%;
  }
  @media (max-width: 440px) {
    height: auto;
    padding-bottom: 38px;
  }
`;

const Header = styled.div`
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  color: #1f2021;
  padding-bottom: 25px;
`;

const Message = styled.div`
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: #777984;
  max-width: 460px;
  margin: 0 auto;

  @media (max-width: 400px) {
    width: 90%;
  }
`;

const EmailSpan = styled.span`
  font-weight: 500;
  color: #1f2021;
`;

const Top = styled.div`
  margin-top: -10px;
`;

const Middle = styled.div`
  width: 579px;
  height: 267px;
  margin: 48px 0;
  padding: 22px 72px 36px;
  box-shadow: 0 5px 20px 0 rgba(38, 38, 38, 0.05);
  background-color: #ffffff;

  @media (max-width: 580px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    padding: 22px 45px 36px;
  }

  @media (max-width: 430px) {
    padding: 22px 30px 36px;
  }

  @media (max-width: 340px) {
    padding: 22px 20px 36px;
  }
`;

const Bottom = styled.div`
  position: relative;
`;

const Resend = styled.div`
  font-size: 16px;
  text-align: center;
  color: #777984;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResendSpan = styled.span`
  color: #1f2021;
  cursor: pointer;
  min-width: 55px;
`;

const ErrorMessage = styled.div`
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgb(238, 80, 80);
  top: -18px;
  text-align: center;
  position: absolute;
  width: 100%;
`;

const CloseDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  color: #9b9b9b;
  padding-right: 26px;
  align-self: flex-start;
  padding-top: 15px;
`;

const CloseDivImage = styled.img`
  width: 17px;
`;

const CloseSpan = styled.span`
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeviceVerify = ({ popup, newRouterInfo }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const customerVerificationEmail = useSelector(
    state => state.userInfo.customerVerificationEmail
  );
  const showSignInPopUpModal = useSelector(
    state => state.userActions.showSignInPopUpModal
  );

  const [loading, setLoadSpinner] = useState(false);
  const [verificationCode, setVerificationCode] = useState();
  const [valid, setValid] = useState(false);
  const [codeError, setCodeError] = useState("");
  const [resendSpinner, setResendSpinner] = useState(false);
  const [resendError, setResendError] = useState("");
  const [submitAttempts, setSubmitAttempts] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => closeModal();
  }, []);

  const closeModal = () => {
    dispatch(defaultAction(SHOW_DEVICE_VERIFY_POPUP_MODAL, false));
  };

  const submitHandler = async () => {
    if (!valid || loading) {
      return;
    }
    setLoadSpinner(true);
    const ctx = {
      props: {
        history: newRouterInfo ? newRouterInfo.history : history,
        location: newRouterInfo ? newRouterInfo.location : location
      }
    };
    const error = await dispatch(verifyDevice(verificationCode, ctx));
    if (error) {
      setCodeError(error);
      setValid(false);
      setSubmitAttempts(submitAttempts + 1);
    } else {
      closeModal();
      if (showSignInPopUpModal) {
        dispatch(defaultAction(HIDE_SIGN_IN_POPUP_MODAL));
      }
    }
    setLoadSpinner(false);
  };

  const resendCode = async () => {
    setResendSpinner(true);
    const error = await dispatch(resendVerifyDeviceCode());
    if (error) {
      setResendError(error);
    }
    setResendSpinner(false);
  };
  return (
    <div
      className={classNames(
        "animated",
        "fast",
        "fadeInUp",
        "device-verify-popup"
      )}
    >
      <Container popup={popup}>
        {popup && (
          <CloseDiv className="mar-t-10">
            <CloseSpan onClick={closeModal}>
              <CloseDivImage
                src="https://cdn.cardcash.com/website/ui/elements/close.svg"
                aria-hidden="true"
                alt="close button"
              />
            </CloseSpan>
          </CloseDiv>
        )}
        <Top>
          <Header>One more step...</Header>
          <Message>
            For your security, we have emailed a verification code to&nbsp;
            <EmailSpan>{customerVerificationEmail}.</EmailSpan>&nbsp;Please
            enter the code now.
          </Message>
        </Top>
        <Middle>
          <StepperInputs
            setVerificationCode={setVerificationCode}
            setValid={setValid}
            setCodeError={setCodeError}
            codeError={codeError}
            submitHandler={submitHandler}
            submitAttempts={submitAttempts}
            valid={valid}
            length={6}
          />
          <DefaultCustomButton
            handleClick={() => {
              submitHandler();
            }}
            classes={"main-default-orange-button"}
            type="button"
            disabled={false}
            buttonType={"ORANGE_BUTTON"}
            name="submit"
          >
            {loading ? (
              <SmallButtonSpinner img="https://cdn.cardcash.com/website/ui/elements/spinner-white.png" />
            ) : (
              <span>Submit Code</span>
            )}
          </DefaultCustomButton>
        </Middle>
        <Bottom>
          {resendError && <ErrorMessage>{resendError}</ErrorMessage>}
          <Resend>
            Didn’t receive the code?&nbsp;
            {resendSpinner ? (
              <ResendSpan>
                <SmallButtonSpinner img="https://cdn.cardcash.com/website/ui/elements/spinner-dark.png" />
              </ResendSpan>
            ) : (
              <ResendSpan onClick={resendCode}>Resend</ResendSpan>
            )}
          </Resend>
        </Bottom>
      </Container>
    </div>
  );
};

export default DeviceVerify;
