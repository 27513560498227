import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import styled from "@emotion/styled";
import RenderFormFieldNew from "./RenderFormFieldNew";
import { firstName, lastName } from "@components/validation";
import CustomSubmitButton from "@components/CustomSubmitButton";
import SmallButtonSpinner from "@components/SmallButtonSpinner";
import { createAccount } from "@actions/accountActions";
import { trimString } from "@utils";
import CustomerExists from "@components/CustomerExists";
import loadable from "@loadable/component";
const MessageContainer = loadable(() =>
  import(/* webpackChunkName: "MessageContainer" */ "../MessageContainer")
);
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

const Form = styled.form`
  margin-top: 20px;
`;

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 26px;
`;

const ButtonWrapper = styled.div`
  margin-top: 32px;
`;

const AdditionalGoogleForm = ({
  handleSubmit,
  invalid,
  pristine,
  googleSignInAdditionalInfo
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoadSpinner] = useState(false);
  const [errorFromServer, setErrorFromServer] = useState(null);

  const submit = async fields => {
    if (loading) return;
    setLoadSpinner(true);
    const alpha = "abcdefghijklmnopqrstuvwxyz";
    const upper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    const numbers = "0123456789";
    const symbols = "@$!%*?";

    let password = "";
    for (let index = 0; index < 9; index++) {
      if (index < 3) {
        const rndInt = Math.floor(Math.random() * alpha.length - 1) + 1;
        password = `${password}${alpha[rndInt]}`;
      } else if (index < 5) {
        const rndInt = Math.floor(Math.random() * upper.length - 1) + 1;
        password = `${password}${upper[rndInt]}`;
      } else if (index < 7) {
        const rndInt = Math.floor(Math.random() * numbers.length - 1) + 1;
        password = `${password}${numbers[rndInt]}`;
      } else {
        const rndInt = Math.floor(Math.random() * symbols.length - 1) + 1;
        password = `${password}${symbols[rndInt]}`;
      }
    }

    const submissionHistoryObject = {
      props: {
        history,
        location
      }
    };

    const accountObj = {
      customer: {
        firstname: trimString(fields.firstname),
        lastname: trimString(fields.lastname),
        email: googleSignInAdditionalInfo.customer.email,
        password
      }
    };
    const serverResponse = await dispatch(
      createAccount(accountObj, submissionHistoryObject)
    );

    if (serverResponse?.error) {
      setErrorFromServer(serverResponse.error);
      setLoadSpinner(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(submit)} className="additional-google-sign-in">
      <CustomerExists />
      {errorFromServer && (
        <div>
          <MessageContainer
            message={errorFromServer}
            classes={"credit-card-form-error-message"}
          />
        </div>
      )}
      <InputWrapper>
        <Field
          name="firstname"
          component={RenderFormFieldNew}
          type="text"
          placeholder="First Name"
          validate={firstName}
          addIcon={"https://cdn.cardcash.com/website/ui/elements/contact.svg"}
          imageContainerStyles={{ left: "20px" }}
          paddingLeft={"45px"}
          setOpacity={true}
        />
      </InputWrapper>
      <InputWrapper>
        <Field
          name="lastname"
          component={RenderFormFieldNew}
          type="text"
          placeholder="Last Name"
          validate={lastName}
          addIcon={"https://cdn.cardcash.com/website/ui/elements/contact.svg"}
          imageContainerStyles={{ left: "20px" }}
          paddingLeft={"45px"}
          setOpacity={true}
        />
      </InputWrapper>
      <ButtonWrapper>
        <CustomSubmitButton
          type="submit"
          styles={{ height: "72px", borderRadius: "36px" }}
          buttonType={"ORANGE_BUTTON"}
          disabled={invalid || pristine}
          disabledBackGroundColor={"rgba(119, 121, 132, 0.48)"}
        >
          {loading ? (
            <SmallButtonSpinner img="https://cdn.cardcash.com/website/ui/elements/spinner-white.png" />
          ) : (
            <span>Sign Up</span>
          )}
        </CustomSubmitButton>
      </ButtonWrapper>
    </Form>
  );
};

export default reduxForm({
  form: "googleAdditionalForm",
  enableReinitialize: true
})(AdditionalGoogleForm);
