import React from "react";
import { loginGoogle } from "@actions/accountActions";
import SmallButtonSpinner from "@components/SmallButtonSpinner";
import styled from "@emotion/styled";
import { GoogleLogin } from "@react-oauth/google";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const SocialSignInDiv = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-top: 24px;
  margin-bottom: 35px;
  font-size: 16px;
  text-align: center;

  @media (max-width: 550px) {
    grid-gap: 10px;
  }
`;

const GoogleButton = styled.div`
  position: relative;
  border-radius: 20px;
  height: 40px;
  color: #fff;
  cursor: wait;
  width: 225px;
  margin: 0 auto;
  background-color: #4688f1;
  &:hover {
    background-color: #3f7ad9;
  }
`;

const FacebookLink = styled(Link)`
  font-size: 13px;
  color: grey;
  text-decoration: underline;
  &:hover {
    color: #12b2ea;
  }
`;

const SpinnerContainer = styled.span`
  position: absolute;
  top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignInUpSocialSignIns = ({
  loginGoogle,
  submissionHistoryObject,
  setGoogleSignInAdditionalInfo,
  googleSDKLoaded,
  closeModal
}) => {
  const onGoogleSignIn = async tokenResponse => {
    if (tokenResponse) {
      const { credential: accessToken } = tokenResponse;
      const response = await loginGoogle(accessToken, submissionHistoryObject);
      if (response?.error?.missingData) {
        setGoogleSignInAdditionalInfo(response.error);
      }
    }
  };

  const onFailedGoogleSignIn = error => {
    console.log("Google login failed", error);
  };

  return (
    <SocialSignInDiv className="social-sign-in-div">
      - or -
      <GoogleButton>
        {googleSDKLoaded ? (
          <GoogleLogin
            onSuccess={onGoogleSignIn}
            onError={onFailedGoogleSignIn}
            theme="filled_blue"
            width="225px"
            size="large"
            logo_alignment="left"
            text="continue_with"
            shape="pill"
            useOneTap
          />
        ) : (
          <SpinnerContainer>
            <SmallButtonSpinner img="https://cdn.cardcash.com/website/ui/elements/spinner-white.png" />
          </SpinnerContainer>
        )}
      </GoogleButton>
      <FacebookLink to="/faqs#accountQuestions" onClick={closeModal}>
        Where has Facebook gone?
      </FacebookLink>
    </SocialSignInDiv>
  );
};

export default connect(
  state => ({
    userInfo: state.userInfo,
    googleSDKLoaded: state.initAppReducer.googleSDKLoaded
  }),
  dispatch => ({
    loginGoogle: (accessToken, ctx) => dispatch(loginGoogle(accessToken, ctx))
  })
)(SignInUpSocialSignIns);
